<template>
  <div>
    <div class="main_dashboard">
      <Sidebar :userInfo="userInfo" v-on:backdrop="backdropMobile"/>
      <div class="main_dashboard__main_content">
        <NavBar/>
        <router-view/>
      </div>
    </div>
    <FooterNewsRoom/>
    <div class="main_dashboard__sidebar--backdrop" v-if="openSidebar" @click="openSidebar = false"></div>
  </div>
</template>

<script>
import NavBar from '@/components/Dashboard/Navbar.vue'
import FooterNewsRoom from '@/components/FooterNewsRoom.vue';
import Sidebar from '@/components/Dashboard/Sidebar.vue';

export default {
  name: 'Dashboard',
  components: {
    Sidebar,
    NavBar,
    FooterNewsRoom,
  },
  data() {
    return {
      openSidebar: false,
      userInfo: {
        name: String(),
        last_name: String()
      }
    };
  },
  beforeCreate() {
    if (!this.$session.exists()) {
      this.$router.push({ name: 'login' });
    }
  },
  mounted(){
    this.getUserInfo();
  },
  methods: {
    getUserInfo(){
      this.userInfo = {
        name: this.$session.get('userInfo').name,
        last_name: this.$session.get('userInfo').last_name
      }
    },
    backdropMobile(backdrop) {
      this.openSidebar = backdrop;
    }
  }
};
</script>
