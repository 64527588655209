<template>
  <div class="main_dashboard__sidebar" :class="{'main_dashboard__sidebar--openSidebar': $parent.openSidebar}">
    <router-link :to="{ name: 'home' }">
      <div class="main_dashboard__logo mt-5">
        <div class="main_dashboard__logo--img">
          <img src="@/assets/img/do-genius-on-white.svg" class="img-fluid">
        </div>
        <div class="main_dashboard__logo--text">
          <h5>
            BUILDING <br>
            TECHNOLOGY <br>
            FOR TECHNOLOGY <br>
            BUILDERS
          </h5>
        </div>
      </div>
    </router-link>
    <ul>
      <!-- Dashboard -->
      <li @click="backdrop()">
        <router-link :to="{name: 'dashboard'}">Dashboard</router-link>
      </li>
      <!-- Posts -->
      <li v-if="checkPermission('List All Posts - Posts') || checkPermission('List Posts - Posts') || checkPermission('Create Post - Posts')">
        <span v-b-toggle.posts-menu>Posts
          <vue-fontawesome icon="chevron-up" class="when-open"></vue-fontawesome>
          <vue-fontawesome icon="chevron-right" class="when-closed"></vue-fontawesome>
        </span>
        <b-collapse id="posts-menu" role="tabpanel">
          <ul>
            <li v-if="checkPermission('List All Posts - Posts') || checkPermission('List Posts - Posts')" @click="backdrop()">
              <router-link :to="{name: 'posts.index'}">All Posts</router-link>
            </li>
            <li v-if="checkPermission('Create Post - Posts')" @click="backdrop()">
              <router-link :to="{name: 'posts.create'}">Add New</router-link>
            </li>
          </ul>
        </b-collapse>
      </li>
      <!-- Users -->
      <li v-if="checkPermission('List Users - Users') || checkPermission('Create User - Users') ||
        checkPermission('List Content Creator - Users') || checkPermission('Create Content Creator - Users')">
        <span v-b-toggle.users-menu>Users
          <vue-fontawesome icon="chevron-up" class="when-open"></vue-fontawesome>
          <vue-fontawesome icon="chevron-right" class="when-closed"></vue-fontawesome>
        </span>
        <b-collapse id="users-menu" role="tabpanel">
          <ul>
            <li v-if="checkPermission('List Users - Users') || checkPermission('List Content Creator - Users')" @click="backdrop()">
              <router-link :to="{name: 'users.index'}">All Users</router-link>
            </li>
            <li v-if="checkPermission('Create User - Users') || checkPermission('Create Content Creator - Users')" @click="backdrop()">
              <router-link :to="{name: 'users.create'}">Add new</router-link>
            </li>
          </ul>
        </b-collapse>
      </li>
      <!-- Marketing -->
      <li v-if="checkPermission('List Forms - Marketing') || checkPermission('List User Lists - Marketing')">
        <span v-b-toggle.marketing>Marketing
          <vue-fontawesome icon="chevron-up" class="when-open"></vue-fontawesome>
          <vue-fontawesome icon="chevron-right" class="when-closed"></vue-fontawesome>
        </span>
        <b-collapse id="marketing" role="tabpanel">
          <ul>
            <li v-if="checkPermission('List Forms - Marketing')" @click="backdrop()">
              <router-link :to="{name: 'forms.index'}">Forms</router-link>
            </li>
          </ul>
        </b-collapse>
      </li>

      <li v-if="checkPermission('List Roles and Permissions - Roles and Permissions') || checkPermission('Create Role - Roles and Permissions')">
        <span v-b-toggle.permissions-menu>Roles & Permissions
          <vue-fontawesome icon="chevron-up" class="when-open"></vue-fontawesome>
          <vue-fontawesome icon="chevron-right" class="when-closed"></vue-fontawesome>
        </span>
        <b-collapse id="permissions-menu" role="tabpanel">
          <ul>
            <li v-if="checkPermission('List Roles and Permissions - Roles and Permissions')" @click="backdrop()">
              <router-link :to="{name: 'permissions.index'}">All Roles</router-link>
            </li>
            <li v-if="checkPermission('Create Role - Roles and Permissions')" @click="backdrop()">
              <router-link :to="{name: 'permissions.create'}">Add New</router-link>
            </li>
          </ul>
        </b-collapse>
      </li>
      <li>
        <span v-b-toggle.session-menu>
          <vue-fontawesome icon="user"></vue-fontawesome>
          {{fullName}}
          <vue-fontawesome icon="chevron-up" class="when-open"></vue-fontawesome>
          <vue-fontawesome icon="chevron-right" class="when-closed"></vue-fontawesome>
        </span>
        <b-collapse id="session-menu" role="tabpanel">
          <ul>
            <li @click="backdrop()">
              <router-link :to="{ name:'account_info.index' }">Account Information</router-link>
            </li>
            <li @click="backdrop()">
              <router-link :to="{ name: 'logout' }">Logout</router-link>
            </li>
          </ul>
        </b-collapse>
      </li>
    </ul>
  </div>
</template>
<script>
export default {
  name: 'Sidebar_dashboard',
  props: {
    userInfo: {
      required: true,
      type: Object
    }
  },
  data() {
    return {
      openSidebar: false
    };
  },
  computed: {
    fullName(){
      if ((this.userInfo.name + ' '+this.userInfo.last_name).length >= 20) {
        return (this.userInfo.name + ' '+this.userInfo.last_name).substring(0, 20) + '...';
      }
      return this.userInfo.name + ' '+this.userInfo.last_name;
    }
  },

  mounted() {
  },

  methods: {
    backdrop() {
      this.$emit('backdrop', false);
    }
  },
};
</script>
